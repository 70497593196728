/*------------------------------------
  Form Check
------------------------------------*/

.form-check-label {
  font-size: $form-check-font-size;
  margin-top: $form-check-margin-y;
}

.form-check {
  .form-check-label {
    margin-top: 0;
  }
}

label.form-control,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

// Validation
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: $form-check-input-border;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $form-check-label-color;
}