/*------------------------------------
  Step Divider
------------------------------------*/

.step-divider {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  height: $step-divider-height;
  font-size: $step-divider-font-size;
  font-weight: $step-divider-font-weight;

  &::after {
    position: absolute;
    top: $step-divider-height + $step-padding-x / 2;
    left: $step-icon-width / 2;
    height: calc(100% - #{$step-divider-height + $step-padding-x / 2 - $step-padding-x});
    border-left: $step-border-width solid $step-border-color;
    content: "";
  }
}