/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @if $color == "secondary" {
      @include button-ghost-variant($value, rgba($primary, .1));
    } @else {
      @include button-ghost-variant($value, $value);
    }

    &.dropdown-toggle::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$value}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>"));
      transition: $btn-transition;
    }

    &.btn-no-focus {
      .btn-check:focus + &,
      &:hover,
      &.focus,
      &:focus {
        color: $value;
        background-color: unset;
        box-shadow: none;

        &:hover {
          color: ($value);
          background-color: rgba($value, .1);
          transform: translateY($btn-transition-translateY);
        }
      }

      &.btn-transition {
        .btn-check:focus + &,
        &:hover,
        &.focus,
        &:focus {
          transform: translateY(0);

          &:hover {
            transform: translateY($btn-transition-translateY);
          }
        }
      }
    }
  }
}

// Ghost Secondary
.btn-ghost-secondary {
  .btn-check:focus + &,
  &:hover,
  &:focus {
    color: $primary;

    &.dropdown-toggle::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$primary}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>"));
    }
  }

  &.btn-no-focus {
    .btn-check:focus + &,
    &:hover,
    &.focus,
    &:focus {
      &:hover {
        color: $primary;
        background-color: rgba($primary, .1);
      }
    }

    &.btn-transition {
      .btn-check:focus + &,
      &:hover,
      &.focus,
      &:focus {
        transform: translateY(0);

        &:hover {
          transform: translateY($btn-transition-translateY);
        }
      }
    }
  }
}