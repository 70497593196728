/*------------------------------------
  Navbar Skins
------------------------------------*/

// Light
.navbar-light {
  // Brand
  .navbar-brand {
    &,
    &:hover,
    &:focus {
      color: $navbar-light-brand-color;
    }
  }

  // Toggler
  .navbar-toggler {
    color: $navbar-light-brand-color;
    border-color: $navbar-light-toggle-border-color;
  }

  // Navbar Nav
  .navbar-nav .nav-link {
    color: $navbar-light-color;

    &.active,
    &:hover,
    &:focus {
      color: $navbar-light-hover-color;
    }

    &.active {
      .dropdown-item-icon {
        color: $navbar-light-hover-color;
        opacity: 1;
      }
    }
  }
}

// Dark
.navbar-dark {
  .navbar-nav {
    .nav-item:hover .nav-link {
      &,
      &:hover,
      &:focus {
        color: $navbar-dark-hover-color;
      }
    }
  }

  .navbar-toggler-text {
    color: $navbar-dark-toggler-text-color;
  }

  .nav-link.dropdown-toggle {
    &::after {
      background-image: $dropdown-toggle-pseudo-light-bg;
    }
  }

  &.navbar-fullscreen {
    &.navbar-expand .navbar-nav {
      background-color: transparent;
    }
    
    .navbar-nav .nav-link:hover,
    .navbar-nav .nav-link:focus {
      &,
      &:hover,
      &:focus {
        color: $navbar-fullscreen-nav-link-hover-color;
      }
    }
  }
}